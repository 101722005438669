import { APICollectionListWithNFT } from '@betablocks/shared/lib/api/collections'
import { APINFT, APITagListWithNFT } from '@betablocks/shared/lib/api/nfts'
import { APIPage } from '@betablocks/shared/lib/api/pages'
import { GetServerSideProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { withUser } from '../auth/withUser'
import StorePage from '../components/Pages/StorePage'

type Props = {
  page: APIPage
  nftsByCollection: APICollectionListWithNFT
  nftsByTag: APITagListWithNFT
  nfts: APINFT[]
}

const Home: React.FC<Props> = ({ page, nftsByCollection, nftsByTag, nfts }) => {
  return (
    <StorePage page={page} nftsByCollection={nftsByCollection} nftsByTag={nftsByTag} nfts={nfts} />
  )
}

export const getServerSideProps: GetServerSideProps = withUser(
  async (me, navData, storefront, api) =>
    async ({ locale, query }) => {
      const { gift, contract, id } = query

      // Handling of email link redirects from gifted airdrop emails
      if (gift && contract && id) {
        let destination
        if (me) {
          destination = `logout/?contract=${contract}&id=${id}/&gift=${gift}/`
        } else {
          destination = `login/?next=${`product/${contract}/${id}/?gift=1/`}`
        }
        return {
          redirect: {
            destination: destination,
            permanent: false,
          },
        }
      }

      const { prvw: previewKey } = query
      const pages = await api.pages.list(previewKey as string)
      const page = pages.results.find((page) => page.isDefault === true)

      if (!page || !page.data) {
        return {
          notFound: true,
        }
      }

      const nftsByCollection = await api.collections.listCollectionsWithNFTs()
      const nftsByTag = await api.nfts.listTagsWithNFTs()
      const { results: nfts } = await api.nfts.list(-1, -1)

      return {
        props: {
          page,
          me,
          navData,
          nftsByCollection,
          nftsByTag,
          nfts,
          storefront,
          ...(await serverSideTranslations(locale, ['common', 'navbar', 'home-page', 'footer'])),
        },
      }
    },
  false
)

export default Home
